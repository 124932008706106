<script>
import EmailIcon from "@/components/About/EmailIcon.vue";
import LinkdinIcon from "@/components/About/LinkdinIcon.vue";

export default {
  name: "People",
  components: { LinkdinIcon, EmailIcon },
  setup() {
    const people = [
      {
        name: "Jacob Sherson",
        img: "/Jacob.jpg",
        role: "Coordinator",
        email: "mailto: sherson@mgmt.au.dk",
        linkedin: "https://dk.linkedin.com/in/jacob-sherson-48828b49",
      },
      {
        name: "Simon Goorney",
        img: "/Simon.jpg",
        role: "Operations and Strategy",
        email: "mailto: simon.goorney@mgmt.au.dk",
        linkedin: "https://dk.linkedin.com/in/simon-goorney-175646a9",
      },
    ];
    return { people };
  },
};
</script>

<template>
  <h2 class="heading2 mt-4">People</h2>
  <div
    class="flex flex-row flex-wrap place-content-center h-full text-center justify-evenly"
  >
    <div
      class="flex flex-col items-center p-2 k1:w-80 w-40"
      v-for="person in people"
      :key="person.name"
    >
      <img
        :src="person.img"
        style="height: 96px; width: 96px"
        class="w-24 object-cover rounded-full"
        :alt="person.name"
      />
      <p class="mt-2 font-medium k1:text-lg text-base">
        {{ person.name }}
      </p>
      <p class="mb-2 leading-none k1:text-base text-xs text-gray-400">
        {{ person.role }}
      </p>
      <div class="flex flex-items-center gap-4 mt-auto">
        <EmailIcon :email="person.email" />
        <LinkdinIcon :linkedin="person.linkedin" />
      </div>
    </div>
  </div>
</template>

<style scoped></style>
